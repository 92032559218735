import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Card from '../components/Card'

import { mediaQuery } from '../utils/media-queries'

const Main = styled.div`
    margin: 0 auto;
    margin-top: -1rem;
    position: relative;
    z-index: 1;

    & > div {
        padding: 1rem;
        margin: 0 1rem;
    }

    ${mediaQuery.isHuge`
        max-width: 90vw;
    `}
`

const ImageWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    max-height: 400px;

    .fade {
        display: none;

        ${mediaQuery.extraHuge`
            display: block;
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 20%,
                rgba(255, 255, 255, 0) 80%,
                rgba(255, 255, 255, 1) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1
        `}
    }
`
const Ul = styled.ul`
    margin: 0 0 1rem;
    padding: 0;
    list-style: none;

    li {
        border-bottom: solid 1px ${props => props.theme.gray};
        margin: 0;
        padding: 0.5rem;
    }
`

const Features = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .box {
        border: solid 1px ${props => props.theme.brandColor1};
        background: ${props => props.theme.grayLight};
        flex: 1;
        border-radius: 0.5rem;
        min-width: 140px;
        margin: 0.25rem;

        .content {
            padding: 0.5rem;
            display: block;
            text-align: center;
        }
    }
`

const Deal = styled.div`
    background: none;
    border-width: 2px;
    display: flex;
    overflow: hidden;
    text-align: center;

    &.box {
        flex: 1.5;
    }

    a {
        background-color: #009846;
        color: #fff;
        text-decoration: none;
        display: block;
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0 0.5rem;

        ${mediaQuery.isBig`
            font-size: 0.8rem
        `}
    }
`

const Price = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 0.5rem 0.25rem;

    .label {
        font-size: 0.7rem;
    }

    .ammount {
        font-weight: bold;
        font-size: 1.3rem;
    }
`

const ComprasPersonalizadas = ({ location }) => {
    const BannerImage = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativePath: { eq: "images/tiendas-corporativas-02.jpg" }
                }
            ) {
                nodes {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `).allFile.nodes[0]

    return (
        <Layout seourl={location.pathname} seotitle="Pedidos Corporativos">
            <ImageWrapper>
                <div className="fade"></div>
                <Img fluid={BannerImage.childImageSharp.fluid} alt="" />
            </ImageWrapper>
            <Main>
                <Card>
                    <h1>Pedidos Corporativos</h1>
                    <Features>
                        <div className="box">
                            <span className="content">
                                Entregas semanales, quincenales o mensuales
                            </span>
                        </div>
                        <div className="box">
                            <span className="content">
                                Efectivo, tarjeta o transferencia
                            </span>
                        </div>
                    </Features>
                    <Ul>
                        <li>
                            Buscamos los productos que ocupés en el comercio que
                            nos indiqués.
                        </li>
                        <li>
                            Seleccionamos los productos más frescos y de mejor
                            calidad.
                        </li>
                        <li>
                            Contacto directo y constante vía Whastapp o llamada.
                        </li>
                        <li>
                            Acatamos las más estrictas medidas sanitarias y de
                            higiene emitidas por el <b>Ministerio de Salud</b>.
                        </li>
                    </Ul>
                    <small>* Ciertas condiciones aplican</small>
                </Card>
            </Main>
        </Layout>
    )
}

export default ComprasPersonalizadas
